import React from 'react'
import ChordCircleLogo from 'assets/ChordCircleLogo.png'
import { useForm } from 'hooks'
import { FormInput, FormSecureInput } from 'components/common'
import { Fields } from 'components/settings/integrations/forms/common'
import { IntegrationForm } from 'components/settings/integrations/forms/IntegrationForm'
import { useIntegrationForm } from 'redux/hooks'
import { useEnvironmentsData } from 'redux/state/environments'
import { ValSchemaGeneric } from 'validationSchemas'
import { JitsuSettings, Integration } from 'types'

type Props = {
  onClose: () => void
  integration?: Integration<JitsuSettings>
}

const getSchema = (settings?: JitsuSettings): ValSchemaGeneric<['apiKey', 'workspaceId']> => ({
  apiKey: {
    label: 'API Key',
    type: 'string',
    initialValue: settings?.apiKey,
  },
  workspaceId: {
    label: 'Workspace ID',
    type: 'string',
    initialValue: settings?.workspaceId,
  },
})

export const JitsuForm: React.FC<Props> = ({ onClose, integration }) => {
  const { submit, isLoading, isEdit } = useIntegrationForm('jitsu', integration, onClose)

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  const loginLink =
    selectedEnvironment === 'production'
      ? 'https://prod.cdp.chord.co'
      : 'https://staging.cdp.chord.co'

  const name = selectedEnvironment === 'production' ? 'CDP (Production)' : 'CDP (Staging)'

  return (
    <IntegrationForm
      title="CDP Settings"
      name={name}
      logo={ChordCircleLogo}
      loginLink={loginLink}
      isLoading={isLoading}
      isEdit={isEdit}
      submit={onSubmit}
      onClose={onClose}
    >
      <Fields>
        <FormSecureInput field={fields.apiKey} customWidth="100%" />
        <FormInput field={fields.workspaceId} customWidth="100%" />
      </Fields>
    </IntegrationForm>
  )
}
