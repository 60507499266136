import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { styled as MuiStyled } from '@mui/material/styles'
import { coreDestinations, DestinationType } from 'components/chord-cdp/destinations'
import { Box, Typography, Card, CardActionArea, CardContent, Chip, Grid } from '@mui/material'
import { Cloud, Storage } from '@mui/icons-material'
import { Button } from '@chordco/component-library'
import { Header } from 'components/common'
import { ArrowLeft } from '@chordco/component-library/components/icons/standardIcons/ArrowLeft'
import AddDestinationForm from 'components/chord-cdp/destinations/AddDestinationForm'

const DestinationCard = MuiStyled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  cursor: 'pointer',
  width: '325px',
  height: '100%',
}))

function groupDestinationTypes(): Record<string, DestinationType[]> {
  const groups: Record<string, DestinationType[]> = {}

  const sortOrder = [
    'Datawarehouse',
    'Product Analytics',
    'CRM',
    'Block Storage',
    'Device Destinations',
    'Special',
  ]

  coreDestinations.forEach(d => {
    if (d.tags) {
      const tags = typeof d.tags === 'string' ? [d.tags] : d.tags
      tags.forEach(tag => {
        groups[tag] = groups[tag] || []
        groups[tag].push(d)
      })
    }
  })
  return Object.entries(groups)
    .sort(([k1], [k2]) => {
      const i1 = sortOrder.indexOf(k1)
      const i2 = sortOrder.indexOf(k2)
      if (i1 === -1 && i2 === -1) {
        return k1.localeCompare(k2)
      }
      if (i1 === -1) {
        return 1
      }
      if (i2 === -1) {
        return -1
      }
      return i1 - i2
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

export function getDestinationIcon(destination?: DestinationType) {
  if (!destination) {
    return <Cloud />
  }
  const tags = (
    destination.tags
      ? typeof destination.tags === 'string'
        ? [destination.tags]
        : destination.tags
      : []
  ).map(t => t.toLowerCase())
  return destination.icon || (tags.includes('datawarehouse') ? <Storage /> : <Cloud />)
}

export const DestinationCatalog: React.FC = () => {
  const history = useHistory()

  const groups = useMemo(() => groupDestinationTypes(), [])

  const [destinationToAdd, setDestinationToAdd] = useState<DestinationType | null>(null)

  const handleDestinationAdded = async () => {
    setDestinationToAdd(null)
    history.push('/chord-cdp')
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Header
          title="Destinations Catalog"
          subtitle="Send data to one of those destinations with Chord CDP"
        />
        <Button purpose="secondary" icon={ArrowLeft} onClick={() => history.push('/chord-cdp')}>
          Back
        </Button>
      </Box>
      <Box mt={2} display="flex" flexDirection="column" flexGrow={1} overflow="auto">
        {Object.keys(groups).length === 0 ? (
          <Typography variant="body1" color="textSecondary" textAlign="center">
            No destinations available.
          </Typography>
        ) : (
          Object.entries(groups).map(([tag, destinations]) => (
            <Box key={tag} mb={3}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                {tag}
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {destinations.length === 1
                  ? '1 destination available'
                  : `${destinations.length} destinations available`}
              </Typography>
              <Grid container spacing={2}>
                {destinations.map(destination => (
                  <Grid item key={destination.id}>
                    <DestinationCard
                      onClick={
                        !destination.comingSoon ? () => setDestinationToAdd(destination) : undefined
                      }
                    >
                      <CardActionArea
                        disabled={destination.comingSoon}
                        aria-label={destination.title}
                      >
                        <CardContent>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box
                              sx={{
                                width: 36,
                                height: 36,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {getDestinationIcon(destination)}
                            </Box>
                            {destination.comingSoon && <Chip label="Coming Soon" color="primary" />}
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              color={destination.comingSoon ? 'text.disabled' : 'textPrimary'}
                            >
                              {destination.title}
                            </Typography>
                            {destination.description && (
                              <Typography variant="body2" color="textSecondary">
                                {destination.description}
                              </Typography>
                            )}
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </DestinationCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))
        )}
      </Box>

      {destinationToAdd && (
        <AddDestinationForm
          destinationType={destinationToAdd}
          onDestinationAdded={handleDestinationAdded}
          onClose={() => setDestinationToAdd(null)}
        />
      )}
    </Box>
  )
}
