import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, TabBar } from '@chordco/component-library'
import { useIsMobile, useAnalyticsTabsHelper } from 'hooks'
import { useLookerData } from 'redux/state/looker'
import { Header, Hr } from '../common'
import { BrowserWarning } from './BrowserWarning'
import { useIsLoading } from 'redux/utils'
import { getAudienceTabs } from './tabs'
import PrebuiltAudiences from './audiences/PrebuiltAudiences'
import { MyAudiences } from './audiences/MyAudiences'
import useAudienceSlug from 'hooks/useAudienceSlug'
import { AddNewAudience } from './audiences/AddNewAudience'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import { ProvisionAudiencesForm } from 'components/provisioning/ProvisionAudiencesForm'
import useUserRole from 'redux/hooks/useUserRole'
import audiencesHelp from 'help/audiences.md'

export interface AudiencesFeatureFlags {
  showMyAudiences: boolean
}

interface AudiencesProps {
  featureFlags: AudiencesFeatureFlags
}

export const Audiences: React.FC<AudiencesProps> = ({ featureFlags }) => {
  const { showMyAudiences } = featureFlags

  const history = useHistory()
  const isMobile = useIsMobile()
  const role = useUserRole()

  const [showProvisioningForm, setShowProvisioningForm] = useState(false)

  const {
    getAvailableUserAudiences,
    state: { availableAudiences, availableUserAudiences },
  } = useLookerData()

  const tabs = useMemo(() => getAudienceTabs(showMyAudiences), [showMyAudiences])

  const [selectedTabId, setSelectedTabId] = useAnalyticsTabsHelper(tabs)

  const { isMyAudiences, isPrebuiltAudiences } = useMemo(() => {
    const path = history.location.pathname
    return {
      isMyAudiences: path.includes('my-audiences'),
      isPrebuiltAudiences: path.includes('prebuilt-audiences'),
    }
  }, [history.location.pathname])

  // This hook will retrieve the slug for the current audience. If the slug cannot be found
  // in the path, it will default to the first available audience for the current active tab.
  const slug = useAudienceSlug(isMyAudiences, availableAudiences, availableUserAudiences)

  const isRefreshingUserAudiences = useIsLoading('getAvailableUserAudiences')

  // Refresh user audiences when the user navigates to the My Audiences tab
  useEffect(() => {
    if (isMyAudiences && getAvailableUserAudiences) {
      getAvailableUserAudiences()
    }
  }, [isMyAudiences])

  const handleDeleteAudience = async () => {
    if (getAvailableUserAudiences) {
      getAvailableUserAudiences().then(() => {
        history.push('/audiences/my-audiences')
      })
    }
  }

  const handleActivateAudience = async () => {
    history.push('/data-activations')
  }

  const handleProvisionAudiences = () => {
    setShowProvisioningForm(false)
  }

  return (
    <>
      <Header title="Audiences" hideBottomBorder={true} helpFile={audiencesHelp}>
        {selectedTabId && (
          <TabBar
            tabs={tabs}
            selectedId={selectedTabId}
            setSelectedId={setSelectedTabId}
            resetOnInvalidSelection
            location="Audiences"
          />
        )}
        <Hr isMobile={isMobile} />
        <BrowserWarning />

        {role === 'superuser' ? (
          <ProvisionAudiencesButton
            icon={Plus}
            variant="tertiary"
            onClick={() => setShowProvisioningForm(true)}
          >
            Configure
          </ProvisionAudiencesButton>
        ) : null}
      </Header>

      <EmbedContainer>
        {isPrebuiltAudiences && <PrebuiltAudiences />}

        {isMyAudiences &&
          !isRefreshingUserAudiences &&
          (slug ? (
            <MyAudiences
              slug={slug}
              options={availableUserAudiences.userAudiences}
              onDeleteAudience={handleDeleteAudience}
              onActivateAudience={handleActivateAudience}
            />
          ) : (
            <AddNewAudience />
          ))}
      </EmbedContainer>

      {showProvisioningForm && (
        <ProvisionAudiencesForm
          onClose={handleProvisionAudiences}
          tenantAudiences={availableAudiences.audiences}
        />
      )}
    </>
  )
}

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ProvisionAudiencesButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 100px; // move slightly to the left of the help button sitting in the top right corner
`
