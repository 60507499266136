import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { Button, H1, H2, standardIcons } from '@chordco/component-library'
import { useExperiments, useIsMobile } from 'hooks'
import { LookEmbed } from 'components/looker/LookEmbed'
import { CopyAudienceModal } from 'components/looker/CopyAudienceModal'
import { AvailableUserAudience } from 'types'
import { Loading } from 'components/common'
import { useAudienceData } from 'components/looker/useAudienceData'

const Audience: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()

  const { audience, embedAudienceUrl, isLoading, isInitialized } = useAudienceData(slug)

  const myAudiencesTreatment = useExperiments('hub_my_audiences_nav_link')

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  const [showCopyModal, setShowCopyModal] = useState(false)

  const handleOnAudienceCopied = (audience?: AvailableUserAudience) => {
    setShowCopyModal(false)

    if (audience) {
      history.push(`/audiences/my-audiences/${audience.slug}`)
    } else {
      // eslint-disable-next-line no-console
      console.error('Cannot find the copied audience')

      history.push(`/audiences/my-audiences`)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (isInitialized && (!audience || !embedAudienceUrl)) {
    return (
      <Wrapper>
        <BackButton
          onClick={() => history.push('/audiences/prebuilt-audiences')}
          icon={standardIcons.ArrowLeft}
          variant="ghost"
        >
          Back
        </BackButton>
        <Heading>Audience Not Found</Heading>
        <p>The requested audience could not be located. Please try another link.</p>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <BackButton
        onClick={() => history.push('/audiences/prebuilt-audiences')}
        icon={standardIcons.ArrowLeft}
        variant="ghost"
      >
        Back
      </BackButton>
      <Header>
        <TextContainer>
          <Heading>{audience?.name}</Heading>
          <SubHeading>{audience?.description}</SubHeading>
        </TextContainer>
        <ButtonsContainer>
          <Button
            purpose="primary"
            icon={standardIcons.DataActivations}
            onClick={() => history.push('/data-activations')}
            name="Activate Audience"
            location={`${audience?.name} - Audience`}
          >
            Activate Audience
          </Button>

          {myAudiencesTreatment?.variation === 'on' && (
            <Button
              purpose="secondary"
              icon={standardIcons.Plus}
              onClick={() => setShowCopyModal(true)}
              name="Use As Template"
              location={`${audience?.name} - Audience`}
            >
              Use As Template
            </Button>
          )}
        </ButtonsContainer>
      </Header>

      <Container>{embedAudienceUrl && <LookEmbed url={embedAudienceUrl} />}</Container>

      {showCopyModal && audience && (
        <CopyAudienceModal
          title={audience.name}
          description={audience.description}
          audienceId={audience.id}
          onClose={() => setShowCopyModal(false)}
          onCopied={handleOnAudienceCopied}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const BackButton = styled(Button)`
  padding-left: 0;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const SubHeading = styled.div`
  font-size: 16px;
  color: ${p => p.theme.ContentTertiary};
`

export default Audience
